import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import sessionReducer from './session';
import groupReducer from './group';
import eventReducer from './event';
import membershipReducer from './membership'
import rsvpReducer from './attendance';

const rootReducer = combineReducers({
    session: sessionReducer,
    groupState: groupReducer,
    eventState: eventReducer,
    attendanceState: rsvpReducer,
    membershipState: membershipReducer
});

let enhancer;

if (process.env.NODE_ENV === 'production') {
    enhancer = applyMiddleware(thunk);
} else {
    const logger = require('redux-logger').default;
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
    return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
